/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
}

.home {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.home-header {
  position: relative;
  text-align: center;
  margin-bottom: 40px;
}

.banner {
  position: relative;
  height: 350px; 
  overflow: hidden;
  align-items: center;
  
}

.banner-image {
  width: 100%;
  height: auto;
}

.profile-container {
  position: absolute;
  top: 50%; 
  left: 50%;
  transform: translate(-50%, -50%);
  
}

.profile-pic {
  /*border-radius: 50%;*/
  width: 250px;
  height: 250px;
  border: 5px solid white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
}

.header-text {
  margin-top: 80px; 
}

.header-text h1 {
  font-size: 2.5em;
  margin-top: 10px;
}

.header-text p {
  font-size: 1.2em;
  margin-top: 10px;
}

section {
  margin-bottom: 40px;
  text-align: center;
}

.intro,
.projects,
.skills,
.contact {
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.project-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.project-item {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.skills-list {
  list-style: none;
  padding: 0;
}

.skills-list li {
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 10px;
}

.skills-list p {
  margin-bottom: 20px;
}

.btn {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background: #0073e6;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  transition: background 0.3s;
}

.btn:hover {
  background: #005bb5;
}
