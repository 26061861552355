.projects {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.projects h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  text-align: center;
}

.projects p {
  font-size: 1.2em;
  margin-bottom: 20px;
  text-align: center;
}

.project-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.project-items {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.project-items h2 {
  font-size: 1.8em;
  margin-bottom: 10px;
}

.project-items p {
  font-size: 1.1em;
  margin-bottom: 10px;
}

.project-items a {
  display: inline-block;
  margin-top: 10px;
  color: #0073e6;
  text-decoration: none;
}

.project-items a:hover {
  text-decoration: underline;
}

.project-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-top: 10px;
}
