* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
}

.navbar {
  background-color: #333;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-list {
  list-style: none;
  display: flex;
  gap: 20px;
}

.navbar-item {
  position: relative;
}

.navbar-link {
  text-decoration: none;
  color: #fff;
  font-size: 1.2em;
  transition: color 0.3s;
}

.navbar-link:hover {
  color: #ff6347;
}

.navbar-link::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -5px;
  left: 0;
  background-color: #ff6347;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.navbar-link:hover::after {
  visibility: visible;
  transform: scaleX(1);
}

.language-switch {
  display: flex;
  gap: 10px;
}

.language-button {
  background: transparent;
  border: 2px solid #fff;
  color: #fff;
  padding: 5px 10px;
  font-size: 1em;
  cursor: pointer;
  transition: background 0.3s, color 0.3s, border 0.3s;
  border-radius: 5px;
}

.language-button.active {
  background: #fff;
  color: #333;
}

.language-button:hover {
  background: #ff6347;
  border-color: #ff6347;
  color: #fff;
}
