.about-me {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.profile-banner {
  text-align: center;
  margin-bottom: 20px;
  position: relative;
  height: 250px;
}

.about-me h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  text-align: center;
}

.intro p,
.about-me p {
  font-size: 1.2em;
  margin-bottom: 20px;
  line-height: 1.6;
  color: #555;
}

.about-me h2 {
  font-size: 2em;
  margin-top: 20px;
  margin-bottom: 15px;
  color: #333;
}

.about-me ul {
  list-style-type: none;
  padding: 0;
  font-size: 1.2em;
  margin-bottom: 20px;
}

.about-me ul li {
  margin: 10px 0;
  color: #555;
}

.about-me a {
  color: #0073e6;
  text-decoration: none;
  font-weight: bold;
}

.about-me a:hover {
  text-decoration: underline;
}

