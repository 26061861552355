.page-not-found {
    text-align: center;
    padding: 50px;
  }
  
  .page-not-found h1 {
    font-size: 5em;
    margin-bottom: 20px;
  }
  
  .page-not-found p {
    font-size: 1.5em;
    margin-bottom: 20px;
  }
  
  .page-not-found .btn {
    display: inline-block;
    padding: 10px 20px;
    background: #0073e6;
    color: white;
    border-radius: 5px;
    text-decoration: none;
    transition: background 0.3s;
  }
  
  .page-not-found .btn:hover {
    background: #005bb5;
  }
  