.contact {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.contact h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.contact p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.contact-info {
  margin: 20px 0;
}

.contact-info h2 {
  font-size: 1.8em;
  margin-bottom: 15px;
}

.contact-info p {
  font-size: 1.2em;
  margin: 10px 0;
}

.contact-info a {
  color: #0073e6;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

.contact-note {
  margin-top: 30px;
  font-size: 1em;
  color: #666;
}
